@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* App.css */
/* Apply some basic resets and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Set up the body and html to fill the viewport */
html, body {
  height: 100%;
}
/* Container for your application */
.app-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Optional: to ensure text is visible over the background */
  text-align: center; /* Optional: centers text within the container */
}
/* Full-screen fixed background image with opacity */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/10.webp'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* Ensures background is behind all content */
  opacity: .6; /* Adjust this value to change the opacity */
}
/* Add some styles for your main content if needed */
.main-content {
  padding: 20px;
  /* Other styles you may want to add */
}
